export class PriceService {
  static format(price, currencySymbol) {
    return `${currencySymbol}${price}`;
  }

  static getFormattedPrimaryServicePrice(primaryService, servicePrice, currencySymbol) {
    const { price, priceInfo, id } = primaryService;
    if (id === 'service') {
      return servicePrice && currencySymbol ? this.format(servicePrice, currencySymbol) : '';
    }
    if (priceInfo) return priceInfo;
    return price && price > 0 ? this.format(price, currencySymbol) : '';
  }

  static getFormattedOptionPrice(option, currencySymbol) {
    return option && option.price && option.price > 0
      ? currencySymbol
        ? this.format(option.price, currencySymbol)
        : ''
      : option.priceInfo;
  }
}
