import { lighten } from 'polished';

export const colors = {
  vccUi: {
    black: '#000000',
    white: '#FFFFFF',
    grey1: 'rgb(30, 30, 30)',
    grey2: 'rgb(65, 65, 65)',
    grey3: 'rgb(100, 100,100)',
    grey4: 'rgb(135, 135, 135)',
    grey5: 'rgb(165, 165, 165)',
    grey6: 'rgb(195, 195, 195)',
    grey7: 'rgb(225, 225, 225)',
    grey8: 'rgb(240, 240, 240)',
    primary: '#2770BE',
    primaryLight: '#008ae6',
    secondary: '#000000',
    secondaryLight: '#161618',
    destructive: '#C60C46',
    destructiveLight: '#db0f4f',
    accentBlue: '#1c6bba',
  },
  custom: {
    lightGrey: 'rgb(204, 204, 204)',
    lightBlue: 'rgb(51, 122, 183)',
    darkBlue: '#003057',
    hover: { background: lighten(0.8, '#003057') },
  },
  vida: {
    headerBackground: '#BAD6F1',
    secondaryText: '#7C8597',
  },
};

export { availableLanguages, i18nSupportedLanguages } from './languages';
