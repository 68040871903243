import React from 'react';
import { HorizontalLayout, Translation, VerticalLayout } from '@vcc-gcs/bookingengine-shared-frontend';
import { Text } from 'vcc-ui';
import { formattedDate, formattedTime } from '../../../../shared/domain/utils/dateUtils';
import { TimeLineSectionHeading } from '../../TimeLineSectionHeading';
import { useTranslation } from 'react-i18next';

const EventTypeDetailsCard = ({ booking = {}, event = {} }) => {
  const { i18n } = useTranslation();
  const isEventTypeNew = () => event.type === 'new';

  return (
    <HorizontalLayout id={'bookingContent-creationInfo'} style={{ margin: '0.5em 0em' }} className={'creationInfo'}>
      <VerticalLayout className={'creationInfo-content'} spacing={10}>
        <TimeLineSectionHeading>
          <Translation textId={`appointments.eventType.appointment.type.${event.type}`} />
        </TimeLineSectionHeading>
        {isEventTypeNew() && booking.caseReference && (
          <HorizontalLayout>
            <Text>
              <Translation textId={`bookings.booking.otherDetails.caseNumber`} />:
            </Text>

            <Text extend={{ fontSize: '1.2em', margin: '2px 5px' }}>{booking.caseReference}</Text>
          </HorizontalLayout>
        )}
        {isEventTypeNew() && (
          <HorizontalLayout>
            <Text>
              <Translation textId={`appointments.eventType.appointment.source`} />:
            </Text>

            <Text
              extend={{
                border: '1px solid',
                borderRadius: '100px',
                padding: '2px',
                fontSize: '1em',
                margin: '0px 5px',
              }}
            >
              {booking.origin.source}
            </Text>
          </HorizontalLayout>
        )}

        {!isEventTypeNew() && event.origin && (
          <HorizontalLayout>
            <Text>
              <Translation textId={`appointments.eventType.appointment.source`} />:
            </Text>

            <Text
              extend={{
                border: '1px solid',
                borderRadius: '100px',
                padding: '2px',
                fontSize: '1em',
                margin: '0px 5px',
              }}
            >
              {event.origin}
            </Text>
          </HorizontalLayout>
        )}

        <HorizontalLayout>
          <Text>
            <Translation textId={`appointments.eventType.appointment.createdTime`} />:
          </Text>

          <Text extend={{ fontSize: '1.2em', margin: '2px 5px' }}>{formattedTime(event.created, i18n.language)}</Text>
        </HorizontalLayout>

        {isEventTypeNew() && booking.origin.userName && (
          <HorizontalLayout>
            <Text>
              <Translation textId={`appointments.eventType.appointment.bookedBy`} />:
            </Text>

            <Text extend={{ fontSize: '1.2em', margin: '2px 5px' }}>{booking.origin.userName}</Text>
          </HorizontalLayout>
        )}

        {isEventTypeNew() && booking.origin.organization && (
          <HorizontalLayout>
            <Text>
              <Translation textId={`appointments.eventType.appointment.organization`} />:
            </Text>

            <Text extend={{ fontSize: '1.2em', margin: '2px 5px' }}>{booking.origin.organization}</Text>
          </HorizontalLayout>
        )}

        {isEventTypeNew() && booking.bookingReference && (
          <HorizontalLayout>
            <Text>
              <Translation textId={`bookings.booking.otherDetails.bookingReference`} />:
            </Text>

            <Text extend={{ fontSize: '1.2em', margin: '2px 5px' }}>{booking.bookingReference}</Text>
          </HorizontalLayout>
        )}

        {isEventTypeNew() && booking.campaignCode && (
          <HorizontalLayout>
            <Text>
              <Translation textId={`bookings.booking.otherDetails.campaignCode`} />:
            </Text>

            <Text extend={{ fontSize: '1.2em', margin: '2px 5px' }}>{booking.campaignCode}</Text>
          </HorizontalLayout>
        )}

        {event.handledBy && (
          <HorizontalLayout>
            <Text>
              <Translation textId={`appointments.eventType.appointment.handledBy`} />:
            </Text>

            <Text extend={{ fontSize: '1.2em', margin: '2px 5px' }}>{event.handledBy}</Text>
          </HorizontalLayout>
        )}

        {event.handled && (
          <HorizontalLayout>
            <Text>
              <Translation textId={`appointments.eventType.appointment.handledTime`} />:
            </Text>

            <Text extend={{ fontSize: '1.2em', margin: '2px 5px' }}>{`${formattedDate(
              event.handled,
              i18n.language,
            )} ${formattedTime(event.handled, i18n.language)}`}</Text>
          </HorizontalLayout>
        )}
      </VerticalLayout>
    </HorizontalLayout>
  );
};

export { EventTypeDetailsCard };
