import React, { useState, useEffect } from 'react';
import { AppointmentsInboxView } from './AppointmentsInboxView';
import {
  useGetBookingEventsByRetailerQuery,
  useGetBookingsByRetailerQuery,
  useBookingStatusSubscriptionQuery,
  useNewBookingCreatedSubscriptionQuery,
  useBookingCancelledSubscriptionQuery,
  useBookingWorkListSubscriptionQuery,
} from './domain/queryHooks';
import { SortingService, sortOrder as order } from './domain/sortingService';
import { SearchService } from './domain/searchService';
import { HeaderService, column } from './domain/headerService';
import { tab } from './domain/tab';
import { useParams } from 'react-router-dom';

const columnsSortOrderMap = HeaderService.getColumnsSortOrderMap();

export const AppointmentsInbox = () => {
  const [sortWithColumn, setSortWithColumn] = useState(column.CREATED);
  const [, setSortOrder] = useState(order.ASCENDING);
  const [selectedTab, setTab] = useState(tab.TODO);
  const [searchTerm, setSearchTerm] = useState();

  const {
    data: bookingEventsData,
    loading: bookingEventsLoading,
    refetch: refetchEvents,
  } = useGetBookingEventsByRetailerQuery();
  const { data: bookingsData, loading: bookingsLoading, refetch: refetchBookings } = useGetBookingsByRetailerQuery();
  const bookingEvents = bookingEventsData && bookingEventsData.bookingEventsByRetailer;
  const bookings = bookingsData && bookingsData.bookingsByRetailer;

  const { id: preSelectedBookingId } = useParams();
  const [selectedBookingId, setSelectedBookingId] = useState(preSelectedBookingId);

  useEffect(() => {
    setSelectedBookingId(preSelectedBookingId);
  }, [preSelectedBookingId]);

  const bookingUpdatedId = useBookingStatusSubscriptionQuery();
  const newBookingId = useNewBookingCreatedSubscriptionQuery();
  const cancelledBookingId = useBookingCancelledSubscriptionQuery();
  const bookingWorkListUpdated = useBookingWorkListSubscriptionQuery();

  useUpdateBookingEventsEffect(bookingEvents);

  useEffect(() => {
    refetchEvents();
    refetchBookings();
  }, [refetchEvents, refetchBookings, bookingUpdatedId, newBookingId, cancelledBookingId, bookingWorkListUpdated]);

  const sortByColumnHeader = (columnHeader) => {
    if (columnHeader === sortWithColumn) {
      columnsSortOrderMap.get(columnHeader).changeSortOrder();
    }
    setSortOrder(columnsSortOrderMap.get(columnHeader).sortOrder);
    setSortWithColumn(columnHeader);
  };

  useChangeTabEffect(selectedTab, sortWithColumn, setSortWithColumn);
  const sortedBookingEvents =
    bookingEvents && SortingService.sortBookingEvents(sortWithColumn, bookingEvents, columnsSortOrderMap);

  const filteredBookings = bookings && SearchService.search(bookings, searchTerm);
  const sortedBookings =
    filteredBookings && SortingService.sortBookings(sortWithColumn, filteredBookings, columnsSortOrderMap);

  return (
    <AppointmentsInboxView
      sortByColumnHeader={sortByColumnHeader}
      loading={bookingEventsLoading || bookingsLoading}
      bookingEvents={sortedBookingEvents}
      bookings={sortedBookings}
      onChangeTab={setTab}
      selectedTab={selectedTab}
      sortColumn={sortWithColumn}
      columnsSortOrderMap={columnsSortOrderMap}
      handleSearch={setSearchTerm}
      selectedBookingId={selectedBookingId}
      onHandleSetSelectedBooking={setSelectedBookingId}
      bookingWorkListUpdated={bookingWorkListUpdated}
    />
  );
};

function hasImportantBookings(bookingEvents = []) {
  let hasImportantBookings;
  let doneChecking = false;
  let poss = 0;
  do {
    if (poss === bookingEvents.length) {
      doneChecking = true;
    } else {
      const { important } = bookingEvents[poss];
      if (important) {
        hasImportantBookings = important;
        doneChecking = important;
      }
      poss++;
    }
  } while (!doneChecking);

  return hasImportantBookings;
}

function useUpdateBookingEventsEffect(bookingEvents) {
  useEffect(() => {
    if (bookingEvents && window.vidaJsHandler) {
      const important = hasImportantBookings(bookingEvents);
      const bookingsCount = bookingEvents.length;
      if (window.vidaJsHandler.updateBookingCount) window.vidaJsHandler.updateBookingCount(bookingsCount, important);
    }
  }, [bookingEvents]);
}

function useChangeTabEffect(selectedTab, sortWithColumn, setSortWithColumn) {
  useEffect(() => {
    if (
      selectedTab === tab.ALL_BOOKINGS &&
      (sortWithColumn === column.CREATED || sortWithColumn === column.EVENT_TYPE)
    ) {
      setSortWithColumn(column.LAST_UPDATED);
    } else if (selectedTab === tab.TODO && sortWithColumn === column.LAST_UPDATED) {
      setSortWithColumn(column.CREATED);
    }
  }, [setSortWithColumn, sortWithColumn, selectedTab]);
}
