import {
  GET_BOOKING_EVENTS_BY_RETAILER_QUERY,
  GET_BOOKINGS_BY_RETAILER_QUERY,
  SUBSCRIPTION_BOOKING_STATUS_QUERY,
  SUBSCRIPTION_NEW_BOOKING_CREATED_QUERY,
  SUBSCRIPTION_BOOKING_CANCELLED_QUERY,
  SUBSCRIPTION_BOOKING_WORK_LIST_UPDATED_QUERY,
} from './queries';
import { useSubscription } from '@apollo/client';
import { useAuthenticatedQuery } from '../../authentication/useAuthenticatedQuery';

export const useGetBookingEventsByRetailerQuery = () => {
  const result = useAuthenticatedQuery(GET_BOOKING_EVENTS_BY_RETAILER_QUERY);
  return result;
};

export const useGetBookingsByRetailerQuery = () => {
  const result = useAuthenticatedQuery(GET_BOOKINGS_BY_RETAILER_QUERY);
  return result;
};

export const useBookingStatusSubscriptionQuery = () => {
  const { data } = useSubscription(SUBSCRIPTION_BOOKING_STATUS_QUERY);
  return data?.bookingStatus;
};

export const useBookingWorkListSubscriptionQuery = () => {
  const { data } = useSubscription(SUBSCRIPTION_BOOKING_WORK_LIST_UPDATED_QUERY);
  return data?.bookingWorkListUpdated;
};

export const useNewBookingCreatedSubscriptionQuery = () => {
  const { data } = useSubscription(SUBSCRIPTION_NEW_BOOKING_CREATED_QUERY);
  return data?.bookingCreated;
};

export const useBookingCancelledSubscriptionQuery = () => {
  const { data } = useSubscription(SUBSCRIPTION_BOOKING_CANCELLED_QUERY);
  return data?.bookingCancelled;
};
