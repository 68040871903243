import React from 'react';

export const CancelledIcon = ({ size = 25, stroke = '#1C274C', tooltip }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    {tooltip && <title>{tooltip}</title>}
    <path
      d="M5.10645 1.85252C5.10645 0.91246 5.86851 0.150391 6.80857 0.150391V0.150391C7.74863 0.150391 8.5107 0.912459 8.5107 1.85252V2.94826C8.5107 3.88832 7.74863 4.65039 6.80857 4.65039V4.65039C5.86851 4.65039 5.10645 3.88832 5.10645 2.94826V1.85252Z"
      fill={stroke}
    />
    <path
      d="M15.3193 1.85252C15.3193 0.91246 16.0814 0.150391 17.0215 0.150391V0.150391C17.9615 0.150391 18.7236 0.912459 18.7236 1.85252V2.94826C18.7236 3.88832 17.9615 4.65039 17.0215 4.65039V4.65039C16.0814 4.65039 15.3193 3.88832 15.3193 2.94826V1.85252Z"
      fill={stroke}
    />
    <path
      d="M2 3C0.895431 3 0 3.89543 0 5V7.5H24V5C24 3.89543 23.1046 3 22 3H2ZM24 9.15H0V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V9.15ZM7.77569 14.4169C7.24153 13.9389 7.24739 13.1705 7.78877 12.7007C8.33016 12.2309 9.20206 12.2375 9.73621 12.7155L11.9176 14.6677L14.1421 12.7073C14.6794 12.2338 15.5505 12.2338 16.0878 12.7073C16.625 13.1808 16.625 13.9484 16.0878 14.4219L13.8483 16.3954L16.2131 18.5117C16.7473 18.9897 16.7414 19.7581 16.2 20.2279C15.6586 20.6977 14.7867 20.6911 14.2526 20.2131L11.9026 18.1101L9.55674 20.1774C9.01947 20.6508 8.14838 20.6508 7.61111 20.1774C7.07384 19.7039 7.07384 18.9362 7.61111 18.4628L9.97192 16.3823L7.77569 14.4169Z"
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill={stroke}
    />
  </svg>
);
