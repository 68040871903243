import { actions } from './actionType';

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.AUTH_ERROR:
      return {};
    /*
     * this event will be emitted on the initial render in the auth-wrapper
     * to force load child components that will emit GQL queries which will
     * redirect to the VTS login page
     */
    case actions.RESET_AUTH_ERROR:
      return {
        ...state,
        userContext: {
          authError: null,
        },
      };
    default:
      return state;
  }
};
