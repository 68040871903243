import React from 'react';
import styled from 'styled-components';
import { HorizontalLayout, Translation } from '@vcc-gcs/bookingengine-shared-frontend';
import { breakpoints } from '../domain/breakpoints';
import { Text, Arrow } from 'vcc-ui';
import { colors } from '../../shared/domain/constants';
import { SortingIcon } from '../../shared/components';
import { sortOrder as order } from '../domain/sortingService';
import { column } from '../domain/headerService';

const size = Object.freeze({
  SMALL: 'SMALL',
  LARGE: 'LARGE',
});

const StyledHorizontalLayout = getStyledHorizontalLayout();
const TodoListHeader = ({ onColumnHeaderClick, sortColumn, headerSize = size.LARGE, columnsSortOrderMap }) => {
  return (
    <StyledHorizontalLayout spacing={10}>
      <HeaderField
        sortColumn={sortColumn}
        onHeaderFieldClick={onColumnHeaderClick}
        field={column.EVENT_TYPE}
        textId={'appointments.headingsList.eventType'}
        style={{
          cursor: 'pointer',
          flex: headerSize === size.SMALL ? '0 0 50px' : '0 0 60px',
          color: sortColumn === column.EVENT_TYPE ? colors.vccUi.black : colors.vccUi.grey5,
          fontSize: headerSize === size.SMALL ? '0.85em' : '1em',
        }}
        sortOrder={columnsSortOrderMap.get(column.EVENT_TYPE).sortOrder}
      />
      <HeaderField
        sortColumn={sortColumn}
        onHeaderFieldClick={onColumnHeaderClick}
        field={column.CREATED}
        textId={'appointments.headingsList.created'}
        style={{
          cursor: 'pointer',
          flex: '1 0 80px',
          color: sortColumn === column.CREATED ? colors.vccUi.black : colors.vccUi.grey5,
          fontSize: headerSize === size.SMALL ? '0.85em' : '1em',
        }}
        sortOrder={columnsSortOrderMap.get(column.CREATED).sortOrder}
      />
      <SharedHeaderFields
        onHeaderFieldClick={onColumnHeaderClick}
        headerSize={headerSize}
        sortColumn={sortColumn}
        columnsSortOrderMap={columnsSortOrderMap}
      />
    </StyledHorizontalLayout>
  );
};

const BookingsListHeader = ({ sortColumn, onColumnHeaderClick, headerSize = size.LARGE, columnsSortOrderMap }) => {
  return (
    <StyledHorizontalLayout spacing={4}>
      <HeaderField
        sortColumn={sortColumn}
        onHeaderFieldClick={onColumnHeaderClick}
        field={column.LAST_UPDATED}
        textId={'appointments.headingsList.lastUpdated'}
        style={{
          cursor: 'pointer',
          flex: '1 0 80px',
          color: sortColumn === column.LAST_UPDATED ? colors.vccUi.black : colors.vccUi.grey5,
          fontSize: headerSize === size.SMALL ? '0.85em' : '1em',
        }}
        sortOrder={columnsSortOrderMap.get(column.LAST_UPDATED).sortOrder}
      />
      <SharedHeaderFields
        onHeaderFieldClick={onColumnHeaderClick}
        headerSize={headerSize}
        sortColumn={sortColumn}
        columnsSortOrderMap={columnsSortOrderMap}
      />
    </StyledHorizontalLayout>
  );
};

const HeaderField = ({ field, textId, style = {}, sortColumn, onHeaderFieldClick, sortOrder }) => {
  return (
    <>
      <Text subStyle="emphasis" extend={style} onClick={() => onHeaderFieldClick(field)}>
        <Translation textId={textId} />
        {field && (
          <div
            style={{
              transform: sortOrder === order.ASCENDING ? 'rotate(-180deg)' : 'rotate(0deg)',
              display: 'inline-block',
            }}
          >
            <SortingIcon size={9} color={sortColumn === field ? colors.vccUi.black : colors.vccUi.grey5} />
          </div>
        )}
      </Text>
    </>
  );
};

const SharedHeaderFields = ({ onHeaderFieldClick, sortColumn, headerSize, columnsSortOrderMap }) => (
  <>
    <HeaderField
      onHeaderFieldClick={onHeaderFieldClick}
      sortColumn={sortColumn}
      field={column.BOOKING_REFERENCE}
      textId={'appointments.headingsList.bookingRef'}
      style={{
        cursor: 'pointer',
        flex: '1 0 60px',
        color: sortColumn === column.BOOKING_REFERENCE ? colors.vccUi.black : colors.vccUi.grey5,
        display: headerSize === size.SMALL ? 'none' : 'block',
      }}
      sortOrder={columnsSortOrderMap.get(column.BOOKING_REFERENCE).sortOrder}
    />
    <HeaderField
      onHeaderFieldClick={onHeaderFieldClick}
      sortColumn={sortColumn}
      field={column.REGISTRATION}
      textId={'bookings.booking.vehicleDetails.registration'}
      style={{
        cursor: 'pointer',
        flex: '1 0 60px',
        color: sortColumn === column.REGISTRATION ? colors.vccUi.black : colors.vccUi.grey5,
        fontSize: headerSize === size.SMALL ? '0.85em' : '1em',
      }}
      sortOrder={columnsSortOrderMap.get(column.REGISTRATION).sortOrder}
    />
    <HeaderField
      onHeaderFieldClick={onHeaderFieldClick}
      sortColumn={sortColumn}
      field={column.VIN}
      textId={'bookings.booking.vehicleDetails.vin'}
      style={{
        cursor: 'pointer',
        flex: '1 0 100px',
        color: sortColumn === column.VIN ? colors.vccUi.black : colors.vccUi.grey5,
        display: headerSize === size.SMALL ? 'none' : 'block',
      }}
      sortOrder={columnsSortOrderMap.get(column.VIN).sortOrder}
    />
    <HeaderField
      onHeaderFieldClick={onHeaderFieldClick}
      sortColumn={sortColumn}
      field={column.CUSTOMER}
      textId={'appointments.headingsList.customer'}
      style={{
        cursor: 'pointer',
        flex: '1 0 100px',
        color: sortColumn === column.CUSTOMER ? colors.vccUi.black : colors.vccUi.grey5,
        display: headerSize === size.SMALL ? 'none' : 'block',
      }}
      sortOrder={columnsSortOrderMap.get(column.CUSTOMER).sortOrder}
    />
    <span
      style={{
        flex: '0 0 5px',
        visibility: 'hidden',
      }}
    >
      <Arrow direction="right" color="#007BCD" />
    </span>
  </>
);

function getStyledHorizontalLayout() {
  return styled(HorizontalLayout)`
    padding: 16px;
    @media (max-width: ${breakpoints.EVENTYPE}px) {
      .eventType-header-column {
        display: none;
      }
    }
    @media (max-width: ${breakpoints.CREATED}px) {
      .created-header-column {
        display: none;
      }
    }
  `;
}

export { TodoListHeader, BookingsListHeader, size };
