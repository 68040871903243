import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Text } from 'vcc-ui';
import { HorizontalLayout, icons, Translation } from '@vcc-gcs/bookingengine-shared-frontend';
import { colors } from '../shared/domain/constants';

const SelectButton = getSelectButton();

export const VehicleDetailsActions = ({
  hasWorklist,
  onHandleVehicleSelect,
  onHandleCancelBooking,
  onHandleWorkList,
  isCancelledEvent,
  renderWorklistButton,
  bookingWorkListUpdated,
  bookingId,
}) => {
  const [createWorkListButtonDisabled, setCreateWorkListButtonDisabled] = useState(false);
  const [lastUpdatedBooking, setLastUpdatedBooking] = useState(bookingWorkListUpdated);

  const onCreateWorkListClick = () => {
    setCreateWorkListButtonDisabled(true);
    setLastUpdatedBooking('');
    onHandleWorkList();
  };

  useEffect(() => {
    if (bookingWorkListUpdated) {
      setLastUpdatedBooking(bookingWorkListUpdated.split('~')[0]);
    }
  }, [bookingWorkListUpdated]);

  useEffect(() => {
    if (lastUpdatedBooking === bookingId) {
      setCreateWorkListButtonDisabled(false);
    }
  }, [
    bookingId,
    bookingWorkListUpdated,
    createWorkListButtonDisabled,
    lastUpdatedBooking,
    setCreateWorkListButtonDisabled,
  ]);

  return (
    <HorizontalLayout
      style={{ marginTop: '2em' }}
      spacing={16}
      wrapRows={true}
      alignHorizontally={'center'}
      alignVertically={'end'}
    >
      <SelectButton className="select-btn" data-test-id={'select-btn'} onClick={onHandleVehicleSelect}>
        <HorizontalLayout distributeEqually={'true'} spacing={8} alignVertically={'center'}>
          <icons.Car fill={colors.vccUi.white} size={20} />
          <Text extend={{ color: colors.vccUi.white }}>
            <Translation textId="bookings.booking.actions.select" />
          </Text>
        </HorizontalLayout>
      </SelectButton>

      {!isCancelledEvent() && (
        <>
          <SelectButton className="select-btn" data-test-id={'cancel-booking-btn'} onClick={onHandleCancelBooking}>
            <HorizontalLayout distributeEqually={'true'} spacing={8} alignVertically={'center'}>
              <Text data-test-id={'cancel-button'} extend={{ color: colors.vccUi.white }}>
                <Translation textId="bookings.booking.actions.cancelBooking" />
              </Text>
            </HorizontalLayout>
          </SelectButton>
          {renderWorklistButton &&
            (hasWorklist ? (
              <SelectButton className="select-btn" data-test-id={'open-work-list-btn'} onClick={onHandleWorkList}>
                <HorizontalLayout distributeEqually={'true'} spacing={8} alignVertically={'center'}>
                  <Text extend={{ color: colors.vccUi.white }}>
                    <Translation textId={'bookings.booking.actions.openWorklist'} />
                  </Text>
                </HorizontalLayout>
              </SelectButton>
            ) : (
              <SelectButton
                className="select-btn"
                data-test-id={'create-work-list-btn'}
                onClick={onCreateWorkListClick}
                disabled={createWorkListButtonDisabled}
              >
                <HorizontalLayout distributeEqually={'true'} spacing={8} alignVertically={'center'}>
                  <Text extend={{ color: colors.vccUi.white }}>
                    <Translation textId={'bookings.booking.actions.createWorklist'} />
                  </Text>
                </HorizontalLayout>
              </SelectButton>
            ))}
        </>
      )}
    </HorizontalLayout>
  );
};

function getSelectButton() {
  return styled.button`
    outline: none;
    padding: 4px 16px;
    margin-bottom: 10px;
    border-radius: 2px;
    background-color: ${(props) => (props.disabled ? colors.vccUi.grey6 : colors.vccUi.accentBlue)};
    border: 1px solid ${(props) => (props.disabled ? colors.vccUi.grey6 : colors.vccUi.accentBlue)};
    color: ${colors.vccUi.white};
    :hover {
      cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    }
  `;
}
