const bookingToSearchString = (booking) =>
  booking.id
    .concat(booking.bookingReference)
    .concat(booking.customer?.firstName)
    .concat(booking.customer?.lastName)
    .concat(booking.customer?.streetAddress)
    .concat(booking.customer?.email)
    .concat(booking.customer?.phone)
    .concat(booking.customer?.postCode)
    .concat(booking.vehicle.licensePlate)
    .concat(booking.vehicle.VIN)
    .concat(booking.caseReference)
    .toLowerCase();

const search = (bookings, searchTerm) => {
  if (!searchTerm) return bookings;
  const tokens = searchTerm.split(' ').filter((t) => t);
  return bookings.filter((booking) => tokens.reduce((p, c) => bookingToSearchString(booking).includes(c) && p, true));
};

export const SearchService = {
  search,
};
