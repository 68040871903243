"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "TranslatedLabelWithData", {
  enumerable: true,
  get: function get() {
    return _TranslatedLabelWithData.TranslatedLabelWithData;
  }
});
Object.defineProperty(exports, "Translation", {
  enumerable: true,
  get: function get() {
    return _Translation.Translation;
  }
});
var _Translation = require("./Translation");
var _TranslatedLabelWithData = require("./TranslatedLabelWithData");