import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { i18nSupportedLanguages } from './shared/domain/constants';

const detectorOptions = {
  order: ['navigator'],
};

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: detectorOptions,
    supportedLngs: i18nSupportedLanguages,
  });

// VIDA supported languages
// "sv-SE", "ja-JP", "es-ES", "fi-FI", "it-IT", "ko-KR", "pt-PT",
// "ru-RU", "th-TH", "zh-TW", "tr-TR", "en-US", "zh-CN", "pl-PL",

export default i18next;
