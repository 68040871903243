import { reducer as userReducer } from './user/domain/reducer';
import _ from 'lodash';

export const reducer = (state, action) => {
  const nextAppState = ApplicationReducer(state, action);
  const nextUserState = userReducer(state.userContext, action);

  const nextState = {
    ...nextAppState,
    userContext: nextUserState,
  };

  if (process.env.NODE_ENV === 'development') {
    console.log('previous state: ', state);
    console.log('action: ', action.type, action);
    console.log('next state: ', nextState);
  }

  if (_.isEqual(nextState, state)) {
    return state;
  }
  return nextState;
};

// do we still need this one?
function ApplicationReducer(state, action) {
  switch (action.type) {
    default:
      return state;
  }
}
