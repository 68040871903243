const availableLanguages = [
  { id: 'en', lngTxt: 'English (United Kingdom)' },
  { id: 'de', lngTxt: 'German' },
  { id: 'fr', lngTxt: 'French' },
  { id: 'it', lngTxt: 'Italia' },
  { id: 'nl', lngTxt: 'Dutch' },
  { id: 'ja', lngTxt: 'Japanese' },
];
const i18nSupportedLanguages = availableLanguages.map((lang) => lang.id.slice(0, 2));
module.exports = {
  availableLanguages,
  i18nSupportedLanguages,
};
