import React, { useContext, useEffect } from 'react';
import { Context } from '../store';
import { Loader } from '@vcc-gcs/bookingengine-shared-frontend';

const AuthWrapper = (props) => {
  const [{ userContext }, dispatch] = useContext(Context);
  console.log('userContext', userContext);
  useEffect(() => {
    // Reset any existing auth error on mount
    dispatch({ type: 'RESET_AUTH_ERROR' });
  }, [dispatch]);

  const isEmptyContext = !userContext || Object.keys(userContext).length === 0;

  return <Loader loading={false}>{!isEmptyContext ? props.children : <>Please refresh your session</>}</Loader>;
};

export { AuthWrapper };
