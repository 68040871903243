"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRealDistanceCalculator = void 0;
var _api = require("@react-google-maps/api");
var _distanceCalculator = require("../services/distanceCalculator");
var _react = require("react");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var useRealDistanceCalculator = exports.useRealDistanceCalculator = function useRealDistanceCalculator(startPosition, retailers, gmSettings) {
  var _useLoadScript = (0, _api.useLoadScript)(_objectSpread({}, gmSettings)),
    isLoaded = _useLoadScript.isLoaded;
  var _useState = (0, _react.useState)(),
    _useState2 = _slicedToArray(_useState, 2),
    results = _useState2[0],
    setResults = _useState2[1];
  var processResult = function processResult(retailers, result) {
    var elements = result && result.rows && result.rows.length && result.rows[0] && result.rows[0].elements;
    if (elements) {
      var tuples = retailers.map(function (r, i) {
        return elements[i] && {
          id: r.id,
          distance: elements[i].distance.value
        };
      });
      setResults(tuples);
    }
  };
  if (isLoaded && startPosition && retailers && retailers.length > 0) {
    var pos = JSON.stringify(startPosition);
    if (!results) {
      setResults([]);
      var service = new window.google.maps.DistanceMatrixService();
      var distanceSorted = retailers.map(function (r) {
        var distance = (0, _distanceCalculator.getDistanceInMeters)({
          latitude: startPosition.lat,
          longitude: startPosition.lng
        }, r.geoCode);
        return _objectSpread(_objectSpread({}, r), {}, {
          distance: distance
        });
      }).sort(function (r1, r2) {
        return r1.distance - r2.distance;
      });
      var relevantRetailers = distanceSorted.slice(0, 50);
      var _loop = function _loop() {
        var retailerChunk = relevantRetailers.slice(i, i + 25);
        var options = {
          origins: ["".concat(startPosition.lat, ",").concat(startPosition.lng)],
          destinations: retailerChunk.map(function (r) {
            return "".concat(r.geoCode.latitude, ",").concat(r.geoCode.longitude);
          }),
          travelMode: 'DRIVING'
        };
        service.getDistanceMatrix(options, function (result) {
          return processResult(retailerChunk, result, pos);
        });
      };
      for (var i = 0; i < relevantRetailers.length; i += 25) {
        _loop();
      }
    }
    return {
      loading: !results || !results.length,
      data: results
    };
  }
  return {
    loading: true,
    data: undefined
  };
};