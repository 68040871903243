import React, { useEffect } from 'react';
import { AppointmentTimelineView } from './AppointmentTimelineView';
import { useGetBookingQuery, useGetMarketData } from './domain/queryHooks';
import { useMutation } from '@apollo/client';
import { HANDLE_BOOKING_EVENT_FLOW, UPDATE_BOOKING_STATUS } from './domain/mutations';

const AppointmentTimeline = ({ bookingId, closeAppointmentDetails, bookingWorkListUpdated }) => {
  const { data, loading, refetch } = useGetBookingQuery(bookingId);
  const bookingData = data && data.booking;
  const marketAttributes = bookingData && bookingData.marketAttributes;
  const language = bookingData?.bookingLanguage;
  const { data: marketData, loading: loadingCurrencyData } = useGetMarketData(marketAttributes, language);

  const [handleBookingEventFlow] = useMutation(HANDLE_BOOKING_EVENT_FLOW, { refetchQueries: ['booking'] });
  const [cancelBookingStatus] = useMutation(UPDATE_BOOKING_STATUS);

  useEffect(() => {
    if (bookingWorkListUpdated) {
      refetch();
    }
  }, [refetch, bookingWorkListUpdated]);

  const handleEvent = (event) => {
    handleBookingEventFlow({
      variables: {
        bookingEvent: {
          eventId: event.eventId,
          bookingId: event.bookingId,
          type: event.type,
          state: event.state,
        },
      },
    });
  };

  const cancelBooking = (bookingId) => {
    cancelBookingStatus({
      variables: {
        id: bookingId,
        booking: {
          state: 'cancelled',
          cancellationAccepted: true,
        },
      },
    });
  };
  return (
    <AppointmentTimelineView
      bookingLoading={loading || loadingCurrencyData}
      booking={bookingData}
      closeAppointmentDetails={closeAppointmentDetails}
      handleEvent={handleEvent}
      cancelBooking={cancelBooking}
      marketData={marketData}
      bookingWorkListUpdated={bookingWorkListUpdated}
    />
  );
};

export { AppointmentTimeline };
