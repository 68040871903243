import { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { Context } from '../store';

export function isAuthErrorCode(code) {
  return ['MISSING_TOKEN', 'TOKEN_EXPIRED', 'UNAUTHENTICATED', 'UNAUTHORIZED_ACCESS'].includes(code);
}

export const useAuthenticatedQuery = (query, options) => {
  const [, dispatch] = useContext(Context);
  const result = useQuery(query, options);

  useEffect(() => {
    if (!result.error) return;
    result.error.graphQLErrors.forEach((gqlErr) => {
      if (isAuthErrorCode(gqlErr.extensions.code)) {
        dispatch({ type: 'AUTH_ERROR', payload: 'Auth_error' });
      }
    });
  }, [dispatch, result.error]);

  return result;
};
