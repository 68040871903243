"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = exports["default"] = function _default() {
  return {
    header: {
      backgroundColor: 'black',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      minHeight: '5vh',
      padding: '1vh 0 1vh 0',
      alignItems: 'center'
    },
    boxLeft: {
      order: '1',
      marginLeft: '5vw'
    },
    boxCenter: {
      flex: '1',
      order: '2',
      display: 'inline',
      color: 'white',
      marginLeft: '2vh',
      fontWeight: '400'
    },
    boxRight: {
      textAlign: 'right',
      flex: '1',
      order: '3',
      color: 'white',
      marginRight: '5vw'
    },
    boxSubheading: {
      order: '4',
      color: 'white',
      width: '100%',
      textAlign: 'center',
      fontSize: '80%'
    }
  };
};