import { BookingsService } from './bookingsService';
import { compareAsc, isValid, compareDesc } from 'date-fns';
import { column } from './headerService';

export const sortOrder = Object.freeze({
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
});

export class SortingService {
  static sortBookings = (sortColumn, bookings, headerColumnsSortOrderMap) => {
    const bookingsCopy = [...bookings];
    const order = headerColumnsSortOrderMap.get(sortColumn).sortOrder;
    switch (sortColumn) {
      case column.BOOKING_REFERENCE:
        return sortBookingsByBookingReference(bookingsCopy, order);
      case column.REGISTRATION:
        return sortBookingsByRegistration(bookingsCopy, order);
      case column.VIN:
        return sortBookingsByVin(bookingsCopy, order);
      case column.CUSTOMER:
        return sortBookingsByCustomer(bookingsCopy, order);
      case column.LAST_UPDATED:
        return sortBookingsByLastUpdated(bookingsCopy, order);
      default:
        return bookingsCopy;
    }
  };

  static sortBookingEvents = (sortColumn, bookingEvents, headerColumnsSortOrderMap) => {
    const tempBookingEvents = [...bookingEvents];
    const order = headerColumnsSortOrderMap.get(sortColumn).sortOrder;
    switch (sortColumn) {
      case column.BOOKING_REFERENCE:
        return sortEventsByBookingReference(tempBookingEvents, order);
      case column.EVENT_TYPE:
        return sortEventsByType(tempBookingEvents, order);
      case column.CREATED:
        return sortEventsByCreated(tempBookingEvents, order);
      case column.REGISTRATION:
        return sortEventsByRegistration(tempBookingEvents, order);
      case column.VIN:
        return sortEventsByVin(tempBookingEvents, order);
      case column.CUSTOMER:
        return sortEventsByCustomer(tempBookingEvents, order);

      default:
        return bookingEvents;
    }
  };
}

const sortEventsByBookingReference = (bookingEvents, order) => {
  return bookingEvents.sort((bookingEvent1, bookingEvent2) =>
    compareBookingReferences(order, bookingEvent1.booking, bookingEvent2.booking),
  );
};

const sortBookingsByBookingReference = (bookings, order) => {
  return bookings.sort((booking1, booking2) => compareBookingReferences(order, booking1, booking2));
};

const sortEventsByType = (bookingEvents, order) => {
  return bookingEvents.sort((bookingEvent1, bookingEvent2) => {
    if (order === sortOrder.ASCENDING) {
      return bookingEvent1.type < bookingEvent2.type ? -1 : 1;
    } else {
      return bookingEvent1.type > bookingEvent2.type ? -1 : 1;
    }
  });
};

const sortEventsByCreated = (bookingEvents, order) => {
  return bookingEvents.sort((bookingEvent1, bookingEvent2) => {
    if (order === sortOrder.ASCENDING) {
      return new Date(bookingEvent1.created) - new Date(bookingEvent2.created);
    } else {
      return -1 * (new Date(bookingEvent1.created) - new Date(bookingEvent2.created));
    }
  });
};

const sortEventsByCustomer = (bookingEvents, order) => {
  return bookingEvents.sort((bookingEvent1, bookingEvent2) =>
    compareCustomers(order, bookingEvent1.booking, bookingEvent2.booking),
  );
};

const sortBookingsByCustomer = (bookings, order) => {
  return bookings.sort((booking1, booking2) => compareCustomers(order, booking1, booking2));
};

const sortEventsByRegistration = (bookingEvents, order) => {
  return bookingEvents.sort((bookingEvent1, bookingEvent2) =>
    compareStrings(order, bookingEvent1.booking.vehicle?.licensePlate, bookingEvent2.booking.vehicle?.licensePlate),
  );
};

const sortBookingsByRegistration = (bookings, order) => {
  return bookings.sort((booking1, booking2) =>
    compareStrings(order, booking1.vehicle.licensePlate, booking2.vehicle.licensePlate),
  );
};

const sortEventsByVin = (bookingEvents, order) => {
  return bookingEvents.sort((bookingEvent1, bookingEvent2) =>
    compareStrings(order, bookingEvent1.booking.vehicle?.VIN, bookingEvent2.booking.vehicle?.VIN),
  );
};

const sortBookingsByVin = (bookings, order) => {
  return bookings.sort((booking1, booking2) => compareStrings(order, booking1.vehicle?.VIN, booking2.vehicle?.VIN));
};

const sortBookingsByLastUpdated = (bookings, order) => {
  return bookings.sort((booking1, booking2) => {
    const lastUpdated1 = new Date(BookingsService.getLastUpdatedDateFromBooking(booking1));
    const lastUpdated2 = new Date(BookingsService.getLastUpdatedDateFromBooking(booking2));
    if (isValid(lastUpdated1) && isValid(lastUpdated2)) {
      if (order === sortOrder.ASCENDING) {
        return compareAsc(lastUpdated1, lastUpdated2);
      } else {
        return compareDesc(lastUpdated1, lastUpdated2);
      }
    }
    return 0;
  });
};

function compareStrings(order, s1, s2) {
  if (s1 && s2) {
    if (order === sortOrder.ASCENDING) {
      return s1.toLowerCase() < s2.toLowerCase() ? -1 : 1;
    } else {
      return s1.toLowerCase() > s2.toLowerCase() ? -1 : 1;
    }
  } else if (s1 && !s2) {
    return order === sortOrder.ASCENDING ? 1 : -1;
  } else {
    return order === sortOrder.ASCENDING ? -1 : 1;
  }
}

function compareBookingReferences(order, booking1, booking2) {
  if (order === sortOrder.ASCENDING) {
    return booking1.bookingReference - booking2.bookingReference;
  } else {
    return booking2.bookingReference - booking1.bookingReference;
  }
}

function compareCustomers(order, booking1, booking2) {
  const customerName1 = booking1.customer?.firstName?.toLowerCase() + booking1.customer?.lastName?.toLowerCase();
  const customerName2 = booking2.customer?.firstName.toLowerCase() + booking2.customer?.lastName?.toLowerCase();
  if (order === sortOrder.ASCENDING) {
    return customerName1 < customerName2 ? -1 : 1;
  } else {
    return customerName1 > customerName2 ? -1 : 1;
  }
}
