import React from 'react';
import { Text } from 'vcc-ui';
import { colors } from '../../shared/domain/constants';

export const TimeLineSectionHeading = ({ children }) => {
  return (
    <Text
      extend={{
        textTransform: 'uppercase',
        color: colors.vida.secondaryText,
        letterSpacing: '1.5px',
      }}
    >
      {children}
    </Text>
  );
};
