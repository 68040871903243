import React from 'react';
import { HorizontalLayout } from '@vcc-gcs/bookingengine-shared-frontend';
import styled from 'styled-components';
import { Cell } from './Cell';
import { breakpoints } from '../domain/breakpoints';
import { BookingsService } from '../domain/bookingsService';
import { NewIcon, ProgessIcon, CancelledIcon } from '../../shared/components';
import { Arrow } from 'vcc-ui';
import { useTranslation } from 'react-i18next';

const StyledHorizontalLayout = getStyledHorizontalLayout();

const Row = ({
  isPartOfTodoList,
  eventType,
  created,
  booking,
  isSelected,
  isImportant,
  restrictNbrOfCells = false,
  ...restProps
}) => {
  const { t } = useTranslation();
  const tooltip = t(`appointments.eventType.${eventType}`);
  const StatusIcon = statusIcon[eventType];
  return (
    <StyledHorizontalLayout
      restrictNbrOfCells={restrictNbrOfCells}
      isImportant={isImportant}
      spacing={20}
      className={isSelected ? 'clicked-row' : 'row'}
      alignVertically="center"
      {...restProps}
    >
      {isPartOfTodoList && (
        <div style={{ marginLeft: 15, marginRight: 25 }}>
          <StatusIcon size={25} stroke={strokeColor[eventType]} tooltip={tooltip} />
        </div>
      )}
      {isPartOfTodoList ? (
        <Cell
          id={'created'}
          item={created}
          type={'date'}
          styles={{
            flex: '1 0 80px',
            fontWeight: restrictNbrOfCells ? '400' : '500',
            fontSize: restrictNbrOfCells ? '0.75em' : '1em',
          }}
        />
      ) : (
        <Cell
          id={'lastUpdated'}
          item={BookingsService.getLastUpdatedDateFromBooking(booking)}
          type={'date'}
          styles={{
            flex: '1 0 80px',
            fontWeight: restrictNbrOfCells ? '400' : '500',
            fontSize: restrictNbrOfCells ? '0.75em' : '1em',
          }}
        />
      )}
      <Cell
        id={'bookingReference'}
        item={booking.bookingReference}
        type={'text'}
        styles={{ flex: '1 0 60px', display: restrictNbrOfCells ? 'none' : 'block' }}
      />
      <Cell
        id={'registration'}
        item={booking.vehicle.formattedLicensePlate}
        type={'text'}
        styles={{ flex: '1 0 60px', fontSize: restrictNbrOfCells ? '0.75em' : '1em' }}
      />
      <Cell
        id={'vin'}
        item={booking.vehicle.VIN}
        type={'text'}
        styles={{ flex: '1 0 100px', display: restrictNbrOfCells ? 'none' : 'block' }}
      />
      <Cell
        id={'customer'}
        item={`${booking.customer.title || ''} ${booking.customer.firstName || ''} ${booking.customer.lastName || ''}`}
        type={'text'}
        styles={{
          flex: '1 0 100px',
          fontSize: restrictNbrOfCells ? '0.85em' : '1em',
          display: restrictNbrOfCells ? 'none' : 'block',
        }}
      />
      <span
        id={'clickIndicator'}
        style={{
          flex: '0 0 5px',
          visibility: isSelected ? 'visible' : 'hidden',
        }}
      >
        <Arrow direction="right" color="#007BCD" />
      </span>
    </StyledHorizontalLayout>
  );
};

function getStyledHorizontalLayout() {
  return styled(HorizontalLayout)`
    padding: ${(props) => (props.restrictNbrOfCells ? '12px' : '16px')};

    &.row {
      border: solid;
      border-color: lightGray;
      border-width: 1px;
      border-radius: 2px;
    }

    &.clicked-row {
      border: solid;
      border-width: 1px;
      border-radius: 2px;
      background-color: #e3f3ff;
      border-color: #007bcd;
    }

    &:hover {
      background-color: #e3f3ff;
      border-color: #007bcd;
      cursor: pointer;
    }

    @media (max-width: ${breakpoints.EVENTYPE}px) {
      .eventType-header-column {
        display: none;
      }
    }
    @media (max-width: ${breakpoints.CREATED}px) {
      .created-header-column {
        display: none;
      }
    }
  `;
}

const statusIcon = {
  new: NewIcon,
  cancelled: CancelledIcon,
  edited: ProgessIcon,
};

const strokeColor = {
  new: '#058A30',
  cancelled: '#BF2012',
  edited: '#19A4A4',
};

export { Row };
