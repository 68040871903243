"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFlexJustifyContent = exports.getFlexAlignItems = void 0;
var getFlexAlignItems = exports.getFlexAlignItems = function getFlexAlignItems(align) {
  switch (align) {
    case 'start':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'end':
      return 'flex-end';
    default:
      return 'stretch';
  }
};
var getFlexJustifyContent = exports.getFlexJustifyContent = function getFlexJustifyContent(justify) {
  switch (justify) {
    case 'start':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'end':
      return 'flex-end';
    default:
      return 'flex-start';
  }
};