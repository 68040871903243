import React from 'react';
import { Row } from '../appointment/Row';
import { VerticalLayout } from '@vcc-gcs/bookingengine-shared-frontend';

export const BookingsList = ({ bookings = [], selectedBookingId, setBookingId }) => {
  return bookings && bookings.length ? (
    <VerticalLayout spacing={5}>
      {bookings.map((booking, index) => (
        <Row
          data-test-id={'booking-list-item'}
          booking={booking}
          key={index}
          onClick={() => setBookingId(booking.id)}
          isSelected={selectedBookingId === booking.id}
          restrictNbrOfCells={!!selectedBookingId}
        />
      ))}
    </VerticalLayout>
  ) : (
    <></>
  );
};
