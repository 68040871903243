"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.metersToMarketUnit = exports.getDistanceInMiles = exports.getDistanceInMeters = exports.getDistanceInKilometers = exports.DistanceCalculator = void 0;
var earthRadiusKm = 6371;
var earthRadiusMeters = 6371000;
var earthRadiusMiles = 3958.8;
var getDistance = function getDistance(fromPoint, toPoint, earthRadius) {
  var degreesLatitude = degreeToRadian(toPoint.latitude - fromPoint.latitude);
  var degreesLongitude = degreeToRadian(toPoint.longitude - fromPoint.longitude);
  var distance = haversineFormula(earthRadius, degreesLatitude, degreesLongitude, fromPoint.latitude, toPoint.latitude);
  return distance;
};

// Calculates the distance between two sets of coordinates on earth using the haversine formula
// https://en.wikipedia.org/wiki/Haversine_formula
var haversineFormula = function haversineFormula(earthRadius, degreesLatitude, degreesLongitude, fromPointLatitude, toPointLatitude) {
  var a = Math.sin(degreesLatitude / 2) * Math.sin(degreesLatitude / 2) + Math.cos(degreeToRadian(fromPointLatitude)) * Math.cos(degreeToRadian(toPointLatitude)) * Math.sin(degreesLongitude / 2) * Math.sin(degreesLongitude / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadius * c;
};
var degreeToRadian = function degreeToRadian(deg) {
  return deg * (Math.PI / 180);
};
var getDistanceInKilometers = exports.getDistanceInKilometers = function getDistanceInKilometers(fromPoint, toPoint) {
  return getDistance(fromPoint, toPoint, earthRadiusKm);
};
var getDistanceInMiles = exports.getDistanceInMiles = function getDistanceInMiles(fromPoint, toPoint) {
  return getDistance(fromPoint, toPoint, earthRadiusMiles);
};
var getDistanceInMeters = exports.getDistanceInMeters = function getDistanceInMeters(fromPoint, toPoint) {
  return getDistance(fromPoint, toPoint, earthRadiusMeters);
};
var metersToMarketUnit = exports.metersToMarketUnit = function metersToMarketUnit(meters, market) {
  if (market.measurementSystem === 'uk') {
    return meters * 0.00062137;
  }
  return meters / 1000;
};
var DistanceCalculator = exports.DistanceCalculator = {
  getDistanceInMeters: getDistanceInMeters,
  getDistanceInMiles: getDistanceInMiles,
  getDistanceInKilometers: getDistanceInKilometers,
  metersToMarketUnit: metersToMarketUnit
};