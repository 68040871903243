import React from 'react';
import { Row } from '../appointment/Row';
import { Translation, VerticalLayout } from '@vcc-gcs/bookingengine-shared-frontend';

export const TodoList = ({ bookingEvents = [], selectedBookingId, setBookingId }) => {
  return bookingEvents && bookingEvents.length ? (
    <VerticalLayout spacing={5}>
      {bookingEvents.map((item, index) => (
        <Row
          data-test-id={'todo-list-item'}
          eventType={item.type}
          created={item.created}
          isPartOfTodoList={true}
          booking={item.booking}
          isImportant={item.important}
          key={index}
          onClick={() => setBookingId(item.booking.id)}
          isSelected={selectedBookingId === item.booking.id}
          restrictNbrOfCells={!!selectedBookingId}
        />
      ))}
    </VerticalLayout>
  ) : (
    <VerticalLayout
      style={{ margin: '10em', fontStyle: 'italic' }}
      alignVertically={'center'}
      alignHorizontally={'center'}
    >
      <Translation textId={'appointments.noEventsFound'} />
    </VerticalLayout>
  );
};
