import React, { createContext, useReducer } from 'react';
import { reducer } from './reducer';
import { UrlUtil } from './shared/domain/utils/urlUtil';
import { parseLanguage } from './shared/domain/utils/languageUtils';

const locale = UrlUtil.getQueryParameterByName('locale') || '';

export const initialState = {
  isE2ETesting: UrlUtil.getQueryParameterByName('e2e') === 'true',
  locale: locale,
  language: parseLanguage(locale),
};

export const Context = createContext(initialState);

export const Store = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};
