import React, { useEffect } from 'react';
import { HorizontalLayout, VerticalLayout } from '@vcc-gcs/bookingengine-shared-frontend';
import { VehicleDetails } from '../vehicle-details/VehicleDetails';
import styled from 'styled-components';
import { Timeline } from './timeline/TimeLine';
import { Spinner } from 'vcc-ui';

const StyledHorizontalLayout = getStyledHorizontalLayout();

const AppointmentTimelineView = ({
  booking = {},
  bookingLoading = false,
  closeAppointmentDetails,
  handleEvent,
  cancelBooking,
  marketData,
  bookingWorkListUpdated,
}) => {
  const vehicle = booking && { ...booking.vehicle };
  const appointmentDetails = booking && booking.appointmentDetails;
  const customerDetails = booking && booking.customer;
  if (vehicle && booking.vehicleObject) {
    vehicle.assets = booking.vehicleObject.assets;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (!bookingLoading) {
    return (
      <StyledHorizontalLayout id={'dataPart'} style={{ flex: '1 0', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
        <VerticalLayout className={'booking'}>
          <Timeline booking={booking} handleEvent={handleEvent} marketData={marketData} />
        </VerticalLayout>
        <VerticalLayout className={'vehicle'}>
          <VehicleDetails
            booking={booking}
            caseReference={booking.caseReference}
            loading={bookingLoading}
            vehicle={vehicle}
            bookingId={booking.id}
            cancelBooking={cancelBooking}
            customerDetails={customerDetails}
            appointmentDetails={appointmentDetails}
            closeAppointmentDetails={closeAppointmentDetails}
            bookingWorkListUpdated={bookingWorkListUpdated}
          />
        </VerticalLayout>
      </StyledHorizontalLayout>
    );
  } else {
    return (
      <HorizontalLayout alignHorizontally={'center'} style={{ width: '100%' }}>
        <Spinner />
      </HorizontalLayout>
    );
  }
};

function getStyledHorizontalLayout() {
  return styled(HorizontalLayout)`
    padding: 5px;
    background-color: #ffffff;
    font-size: 1em;
    margin-bottom: 2px;
    .card {
    }
    .booking {
      flex: 1 0 70%;
    }
    .vehicle {
      flex: 1 0 30%;
      border-left: 1px solid lightgrey;
    }
  `;
}

export { AppointmentTimelineView };
