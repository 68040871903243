import React from 'react';
import { HorizontalLayout, VerticalLayout } from '@vcc-gcs/bookingengine-shared-frontend';
import { Text } from 'vcc-ui';
import { TimeLineSectionHeading } from '../TimeLineSectionHeading';
import { colors } from '../../../shared/domain/constants';
import styled from 'styled-components';
import { PriceService } from '../../domain/priceService';

import { useTranslation } from 'react-i18next';

const textStyles = {
  color: colors.vida.secondaryText,
  letterSpacing: '1.5px',
};

const StyledListItem = getStyledListItem();

export const Work = ({
  currencySymbol = '',
  servicePrice,
  primaryServices = [],
  additionalOptions = [],
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <HorizontalLayout id={'bookingContent-work'} {...restProps}>
      <VerticalLayout spacing={10}>
        <TimeLineSectionHeading>{t(`appointments.eventType.appointment.work`)}</TimeLineSectionHeading>
        <ul>
          {primaryServices.map((service, index) => (
            <StyledListItem key={index}>
              <HorizontalLayout spacing={6}>
                <Text>{`${service.heading} ${getDetailsText(service)}`}</Text>
                <Text>{PriceService.getFormattedPrimaryServicePrice(service, servicePrice, currencySymbol)}</Text>
              </HorizontalLayout>
            </StyledListItem>
          ))}
        </ul>
        {additionalOptions.length > 0 && (
          <>
            <Text extend={textStyles}>{t(`appointments.eventType.appointment.additionals`)}</Text>
            <ul>
              {additionalOptions.map((option, index) => (
                <StyledListItem key={index}>
                  <HorizontalLayout distributeEqually={true} spacing={20}>
                    <Text>{option.description}</Text>
                    <Text className="additional-option-price">
                      {PriceService.getFormattedOptionPrice(option, currencySymbol)}
                    </Text>
                  </HorizontalLayout>
                </StyledListItem>
              ))}
            </ul>
          </>
        )}
      </VerticalLayout>
    </HorizontalLayout>
  );
};

function getDetailsText(service) {
  const details = service && (service.details || service.subcaption);

  return details ? `- ${details}` : '';
}

function getStyledListItem() {
  return styled.li`
    list-style-type: circle;
    flex: 1 1 auto;

    .additional-option-price {
      text-align: right;
    }
  `;
}
