import React from 'react';

export const InformationIcon = ({ color = '#284E80', className = '', size = 24, ...restProps }) => (
  <svg width={size} height={size} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
    <circle strokeWidth="1.5" stroke={color} r="21.5" cy="23" cx="23" />
    <path
      fill={color}
      d="m24.422862,34.937475l0,-16.663463l-2.845722,0l0,16.663463m-0.047429,-19.744101l2.94058,0l0,-4.13085l-2.94058,0l0,4.13085z"
    />
  </svg>
);
