import React from 'react';
import { Text } from 'vcc-ui';
import styled from 'styled-components';
import { Event } from './event/Event';
import { Translation, VerticalLayout } from '@vcc-gcs/bookingengine-shared-frontend';
import { colors } from '../../shared/domain/constants';

const Timeline = ({ booking = {}, handleEvent, marketData }) => {
  const StyledVerticalLayout = getStyledVerticalLayout();
  const StyledHeaderLayout = getStyledHeaderLayout();
  const eventsCount = booking?.events?.length || 0;
  return (
    <>
      <StyledHeaderLayout>
        <Text className={'heading'} data-test-id={'timeLineViewHeading'}>
          <Translation textId={`appointments.timeLine`} />
        </Text>
      </StyledHeaderLayout>
      {booking?.events?.length ? (
        <StyledVerticalLayout spacing={20}>
          {booking.events.map((event, index) => {
            return (
              <Event
                key={index}
                handleEvent={handleEvent}
                eventNumber={eventsCount - index}
                event={event}
                booking={booking}
                marketData={marketData}
              />
            );
          })}
        </StyledVerticalLayout>
      ) : (
        <></>
      )}
    </>
  );
};

function getStyledVerticalLayout() {
  return styled(VerticalLayout)`
    justify-content: flex-start;
  `;
}

function getStyledHeaderLayout() {
  return styled(VerticalLayout)`
    justify-content: center;
    margin-top: 1em;
    .heading {
      margin: 1.5em 3em;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      font-family: Arial;
      color: ${colors.vida.secondaryText};
      font-style: normal;
      font-weight: normal;
      font-size: 1em;
      line-height: 0px;
    }
  `;
}

export { Timeline };
