import React from 'react';
import { HorizontalLayout, VerticalLayout, TranslatedLabelWithData } from '@vcc-gcs/bookingengine-shared-frontend';
import { TimeLineSectionHeading } from '../TimeLineSectionHeading';
import { Text } from 'vcc-ui';
import { useTranslation } from 'react-i18next';

export const ServicePlanCard = ({ servicePlan, servicePlanAdditionalInfo }) => {
  const { t } = useTranslation();
  return (
    <HorizontalLayout id={'bookingContent-date-time'} style={{ margin: '0.5em 0em' }} className={'date-time'}>
      <VerticalLayout data-test-id={'servicePlan'} className={'date-time-content'} spacing={10}>
        <TimeLineSectionHeading>{t(`appointments.eventType.appointment.servicePlan.title`)}</TimeLineSectionHeading>
        <ul>
          <li>
            <Text data-test-id={'servicePlan'} as="div">
              <TranslatedLabelWithData
                textId={`appointments.eventType.appointment.servicePlan.planType`}
                data={servicePlan.label}
              />
            </Text>
          </li>
          {servicePlanAdditionalInfo && (
            <li>
              <Text data-test-id={'servicePlanAdditionalInfo'}>{servicePlanAdditionalInfo}</Text>
            </li>
          )}
        </ul>
      </VerticalLayout>
    </HorizontalLayout>
  );
};
