import React from 'react';
import { HorizontalLayout } from '@vcc-gcs/bookingengine-shared-frontend';
import { colors } from '../../domain/constants';
import { useTranslation } from 'react-i18next';

export const VersionIdentifier = ({ buildId }) => {
  const { t } = useTranslation();
  return (
    <HorizontalLayout alignHorizontally={'end'} style={{ width: '100%' }}>
      <HorizontalLayout
        data-test-id={'version-identifier'}
        style={{ margin: '0em 0.8em 0.5em', color: colors.vccUi.grey5, fontSize: '0.75em' }}
      >
        {t(`common.version`)}: {buildId}
      </HorizontalLayout>
    </HorizontalLayout>
  );
};
