import { isValid, compareDesc } from 'date-fns';

export class BookingsService {
  static getLastUpdatedDateFromBooking(booking) {
    const events = booking && booking.events;
    const bookingTimestamp = booking.bookingTimestamp;
    if (events && events.length) {
      const validDates = events
        .map((event) => new Date(event.created))
        .filter((created) => {
          return isValid(created);
        });
      const sortedDates = validDates.sort(compareDesc);
      return sortedDates && sortedDates.length && sortedDates[0];
    } else if (bookingTimestamp) {
      const bookingDate = new Date(bookingTimestamp);
      return isValid(bookingDate) ? bookingDate : undefined;
    }
    return undefined;
  }
}
