import { GET_BOOKING, GET_MARKET_DATA } from './queries';
import { useAuthenticatedQuery } from '../../authentication/useAuthenticatedQuery';

export const useGetBookingQuery = (bookingId) => {
  const result = useAuthenticatedQuery(GET_BOOKING, {
    variables: { bookingId },
    skip: !bookingId,
    errorPolicy: 'all',
  });

  return result;
};

export const useGetMarketData = (marketAttributes = {}, language) => {
  const { market } = marketAttributes;
  const result = useAuthenticatedQuery(GET_MARKET_DATA, {
    variables: { marketId: market, language },
    skip: !market,
  });
  return result;
};
