import { ColumnSortOrder } from './headerDataModels';
import { sortOrder } from './sortingService';

export const column = Object.freeze({
  BOOKING_REFERENCE: 'BOOKING_REFERENCE',
  EVENT_TYPE: 'EVENT_TYPE',
  CREATED: 'CREATED',
  REGISTRATION: 'REGISTRATION',
  VIN: 'VIN',
  CUSTOMER: 'CUSTOMER',
  LAST_UPDATED: 'LAST_UPDATED',
});

export class HeaderService {
  static getColumnsSortOrderMap() {
    const columnsMap = new Map();
    Object.keys(column).forEach((column) => {
      columnsMap.set(column, new ColumnSortOrder(sortOrder.ASCENDING));
    });
    return columnsMap;
  }
}
