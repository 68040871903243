import React from 'react';
import { Text } from 'vcc-ui';
import { Translation } from '@vcc-gcs/bookingengine-shared-frontend';
import { formattedDate } from '../../shared/domain/utils/dateUtils';
import { useTranslation } from 'react-i18next';

const CellTypeDate = ({ data, styles }) => {
  const { i18n } = useTranslation();

  return <Text extend={styles}>{getFormattedDate(data, i18n.language)}</Text>;
};

const CellTypeText = ({ data, styles }) => {
  return (
    <Text extend={styles}>
      <Translation textId={data} />
    </Text>
  );
};

const Cell = ({ item, type, styles = {} }) => {
  if (type === 'date') {
    return <CellTypeDate data={item} styles={styles} />;
  } else {
    return <CellTypeText data={item} styles={styles} />;
  }
};

function getFormattedDate(timestamp, language) {
  const date = timestamp && new Date(timestamp);
  return date ? formattedDate(date, language) : undefined;
}

export { Cell };
