import { ApolloClient } from '@apollo/client/core';
import { InMemoryCache } from '@apollo/client/core';
import { ApolloLink, createHttpLink, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { isAuthErrorCode } from './authentication/useAuthenticatedQuery';
import { ApplicationVersion } from './application-version';

const cache = new InMemoryCache({
  typePolicies: {
    Booking: {
      keyFields: ['id'],
    },
    BookingEvent: {
      keyFields: ['eventId'],
    },
    Event: {
      keyFields: ['eventId'],
    },
    Product: {
      keyFields: ['uid'],
    },
    DropoffOption: {
      keyFields: ['uid'],
    },
    Option: {
      keyFields: ['uid'],
    },
    Dealer: {
      keyFields: ['id'],
    },
  },
});
const VTS_URL = window.config.vtsUrl; //'https://vts-prod.volvocars.biz/vts/login?login=';
const SECURITY_DOMAIN = window.config.securityDomain;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((gqlErr) => {
      const { extensions } = gqlErr;
      if (extensions.code === 'FORBIDDEN') {
        window.location = '/forbidden';
      }
      //MISSING_TOKEN signifies that the VTS token is missing so allow the user a login attempt.
      if (isAuthErrorCode(extensions.code) && window.vidaJsHandler === undefined) {
        // const appPath = window.location.pathname;
        console.log(`[GraphQL error]: Message: ${gqlErr.message}, Code: ${extensions.code}, Path: ${gqlErr.path}`);
        const redirectionURL = window.location.href;
        const loginObject = {
          securityDomain: SECURITY_DOMAIN ? SECURITY_DOMAIN : 'WorkshopManagementExtVTS',
          uri: redirectionURL,
        };
        console.log(`loginObject: ${loginObject}, ${loginObject.securityDomain}`);
        window.location = `${VTS_URL}${btoa(JSON.stringify(loginObject))}`;
      } else {
        console.log(`[GraphQL error]: Message: ${gqlErr.message}, Location: ${gqlErr.locations}, Path: ${gqlErr.path}`);
      }
    });
  } else if (networkError) {
    console.log('Network Error', networkError);
  }
});
const authorizatonLink = setContext(async (_, { headers }) => {
  const authHeader =
    process.env.NODE_ENV === 'development'
      ? {
          authorization: process.env.REACT_APP_VTS_TOKEN,
        }
      : {};
  return {
    headers: {
      ...headers,
      ...authHeader,
      'application-version': ApplicationVersion,
    },
  };
});

const CLIENT_URI = process.env.NODE_ENV === 'development' ? '/q' : `${process.env.PUBLIC_URL}/graphql`;
const CLIENT_SUBSCRIPTION_URI =
  process.env.NODE_ENV === 'development'
    ? `${process.env.REACT_APP_SUBSCRIPTION_URL}/subscriptions`
    : `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/subscriptions`;

const httpLink = createHttpLink({
  uri: CLIENT_URI,
  credentials: 'include',
});

const client = createClient({
  url: CLIENT_SUBSCRIPTION_URI,
  lazy: false,
  retryAttempts: Infinity,
  shouldRetry: () => true,
  connectionParams: () => {
    // If the browser request has cookie header applied then the below is not used by subscription service.
    // Even though in the subscription handshake request will show this token in the payload (authToken). But in this case it will
    // be ignored by the service.
    if (process.env.NODE_ENV === 'development') {
      return {
        authToken: process.env.REACT_APP_VTS_TOKEN,
      };
    }
  },
  on: {
    connected: () => console.log('client connected'),
    closed: () => console.log('client connection closed'),
    error: () => console.log('client connection failed'),
  },
});

// Create a WebSocket link:
const wsLink = new GraphQLWsLink(client);

const terminalLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const link = ApolloLink.from([errorLink, authorizatonLink, terminalLink]);

const apolloClient = new ApolloClient({
  name: 'retailer-web',
  version: process.env.REACT_APP_BUILD_ID || 'second-folio',
  cache,
  clientState: {
    defaults: {},
    resolvers: {},
  },
  link,
});

export default apolloClient;
