import React from 'react';
import { useLocation } from 'react-router-dom';
import { HorizontalLayout, Loader, VerticalLayout } from '@vcc-gcs/bookingengine-shared-frontend';
import styled from 'styled-components';
import { Block, Text } from 'vcc-ui';
import { Cross } from '../shared/components/icons/Cross';
import { VehicleInformationView } from './VehicleInformationView';
import { ConsumerDetailsView } from './ConsumerDetailsView';
import { VehicleDetailsActions } from './VehicleDetailsActions';
import i18next from 'i18next';

const StyledVerticalLayout = getStyledVerticalLayout();

const VehicleDetailsView = ({
  vehicle = {},
  customerDetails = {},
  appointmentDetails = {},
  loading,
  closeAppointmentDetails,
  cancelBooking,
  bookingId,
  booking,
  bookingWorkListUpdated,
}) => {
  const searchParams = new URLSearchParams(useLocation().search);

  const isCancelledEvent = () => {
    //if there is only one event then it has to be of type new return false
    if (booking?.events?.length === 1) {
      return false;
    } else {
      // If there are more than 1 events then check if there is a cancelled event present
      const event = booking?.events.find((event) => event.type === 'cancelled');
      if (event) {
        return true;
      }
    }
  };

  const handleCancelBooking = () => {
    const confirmation = window.confirm(i18next.t('bookings.booking.actions.confirmCancelBooking'));

    if (confirmation) {
      cancelBooking(bookingId);
      closeAppointmentDetails(-1);
    }
  };

  const handleWorkList = () => {
    const { VIN, licensePlate } = vehicle;
    const { worklistId } = booking;
    if (window.vidaJsHandler?.createWorklist && !worklistId) {
      return window.vidaJsHandler?.createWorklist(VIN, licensePlate, bookingId);
    } else if (window.vidaJsHandler?.openWorklist && worklistId) {
      return window.vidaJsHandler?.openWorklist(VIN, licensePlate, worklistId);
    }
  };

  const handleVehicleDetails = (e) => {
    e.preventDefault();
    const { VIN } = vehicle;
    return window.vidaJsHandler?.viewVehicleDetails(VIN);
  };

  const handleVehicleSelect = () => {
    const { VIN, licensePlate } = vehicle;
    const paramVin = VIN ? VIN : null;
    const paramLicensePlate = licensePlate ? licensePlate : null;
    const paramBookingId = bookingId ? bookingId : null;

    if (window.vidaJsHandler?.openVehicleBooking) {
      return window.vidaJsHandler?.openVehicleBooking(paramVin, paramLicensePlate, paramBookingId);
    } else {
      return window.vidaJsHandler?.openVehicle(paramVin);
    }
  };

  const shouldRenderWorklistButton = () => {
    const env = searchParams.get('env')?.toUpperCase();
    const createWorklist = !!window.vidaJsHandler?.createWorklist;
    const openWorklist = !!window.vidaJsHandler?.openWorklist;
    const hasVidaFunctions = createWorklist && openWorklist;
    return hasVidaFunctions || env === 'DEV';
  };

  return (
    <Loader loading={loading}>
      <StyledVerticalLayout>
        <Block
          onClick={() => closeAppointmentDetails(-1)}
          extend={{ alignSelf: 'flex-end', margin: '3px', cursor: 'pointer' }}
        >
          <Cross size={20} />
        </Block>
        <VehicleInformationView onHandleViewVehicleDetails={handleVehicleDetails} vehicle={vehicle} />
        <ConsumerDetailsView consumerDetails={customerDetails} />

        <HorizontalLayout className={'content'}>
          <Text className={'content-text-owner'}>{appointmentDetails.pickupAddress || ''}</Text>
        </HorizontalLayout>
        <HorizontalLayout className={'content'}>
          <Text className={'content-text-owner'}>{appointmentDetails.returnAddress || ''}</Text>
        </HorizontalLayout>

        <VehicleDetailsActions
          hasWorklist={!!booking.worklistId}
          renderWorklistButton={shouldRenderWorklistButton()}
          isCancelledEvent={isCancelledEvent}
          onHandleCancelBooking={handleCancelBooking}
          onHandleVehicleSelect={handleVehicleSelect}
          onHandleWorkList={handleWorkList}
          bookingId={bookingId}
          bookingWorkListUpdated={bookingWorkListUpdated}
        />
      </StyledVerticalLayout>
    </Loader>
  );
};

function getStyledVerticalLayout() {
  return styled(VerticalLayout)`
    // border:1px dashed red;
    padding: 0px 8px 0px 8px;
    background-color: #ffffff;
    // flex: 1 0;
    align-items: space-between;
    // max-height: 92vh;
    // overflow: auto;
    .title-headings {
      margin: 5px 0px;
      color: #030a23;
      font-size: 1em;
      font-family: Arial;
      font-style: normal;
      font-weight: bold;
    }
    .content {
      display: flex;
      justify-content: flex-start;
      flex: 1;
    }

    .content-headings {
      flex: 1 0;

      color: #818181;
      font-size: 0.9em;
      font-family: Arial;
      font-style: normal;
    }

    .content-text {
      flex: 1 0;
      color: #333333;
      font-size: 0.9em;
      font-family: Arial;
      font-style: normal;
      text-align: end;
    }
    .content-text-owner {
      flex: 1 0;
      color: #333333;
      font-size: 0.9em;
      font-family: Arial;
      font-style: normal;
    }
  `;
}

export { VehicleDetailsView };
