import gql from 'graphql-tag';

export const UPDATE_EVENT_STATUS = gql`
  mutation updateEventStatus($bookingEvent: InputBookingEvent) {
    updateEventStatus(bookingEvent: $bookingEvent)
  }
`;

export const HANDLE_BOOKING_EVENT_FLOW = gql`
  mutation handleBookingEventFlow($bookingEvent: InputBookingEvent) {
    handleBookingEventFlow(bookingEvent: $bookingEvent)
  }
`;

export const UPDATE_BOOKING_STATUS = gql`
  mutation updateBookingStatus($id: String, $booking: InputBookingState) {
    updateBookingStatus(id: $id, booking: $booking) {
      id
    }
  }
`;
