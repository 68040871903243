import { sortOrder as order } from './sortingService';

export class ColumnSortOrder {
  constructor(sortOrder = order.ASCENDING) {
    this.sortOrder = sortOrder;
  }

  changeSortOrder() {
    if (this.sortOrder === order.ASCENDING) {
      this.sortOrder = order.DESCENDING;
    } else {
      this.sortOrder = order.ASCENDING;
    }
  }
}
