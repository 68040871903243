import React from 'react';
import { HorizontalLayout, VerticalLayout } from '@vcc-gcs/bookingengine-shared-frontend';
import { Text } from 'vcc-ui';
import { useTranslation } from 'react-i18next';
import { TimeLineSectionHeading } from '../TimeLineSectionHeading';

export const CustomerInfoCard = ({ customerInformation }) => {
  const { t } = useTranslation();
  return (
    <HorizontalLayout
      id={'bookingContent-customercomments'}
      style={{ margin: '0.5em 0em' }}
      className={'customercomments'}
    >
      <VerticalLayout className={'customercomments-content'} spacing={10}>
        <TimeLineSectionHeading>{t(`appointments.eventType.appointment.customerComments`)}</TimeLineSectionHeading>
        <Text className={'customercomments-text'}>{`${customerInformation}`}</Text>
      </VerticalLayout>
    </HorizontalLayout>
  );
};
