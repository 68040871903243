import React from 'react';
import { Text } from 'vcc-ui';
import { HorizontalLayout, Translation } from '@vcc-gcs/bookingengine-shared-frontend';

export const ConsumerDetailsView = ({ consumerDetails = {} }) => {
  const { title, firstName, lastName, streetAddress, city, postCode, email, phone } = consumerDetails;
  return (
    <>
      <Text className={'title-headings'}>
        <Translation textId="bookings.booking.consumerDetails.title" />
      </Text>
      <HorizontalLayout style={{ marginTop: '5px' }} className={'content'}>
        <Text className={'content-text-owner'}>{`${title || ''} ${firstName || ''} ${lastName || ''}`}</Text>
      </HorizontalLayout>
      <HorizontalLayout className={'content'}>
        <Text className={'content-text-owner'}>{`${streetAddress || ''} ${city || ''} ${postCode || ''}`}</Text>
      </HorizontalLayout>
      <HorizontalLayout className={'content'}>
        <Text className={'content-text-owner'}>{email || ''}</Text>
      </HorizontalLayout>
      <HorizontalLayout className={'content'}>
        <Text className={'content-text-owner'}>{phone || ''}</Text>
      </HorizontalLayout>
    </>
  );
};
