import React from 'react';

export const ProgessIcon = ({ size = 25, stroke = '#1C274C', tooltip }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    {tooltip && <title>{tooltip}</title>}
    <rect x="6" width="2" height="5" rx="1" fill={stroke} />
    <rect x="16" width="2" height="5" rx="1" fill={stroke} />
    <path
      d="M0 5C0 3.89543 0.895431 3 2 3H22C23.1046 3 24 3.89543 24 5V6H0V5ZM0 8H24V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V8ZM8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14H16C16.5523 14 17 13.5523 17 13C17 12.4477 16.5523 12 16 12H8ZM7 16C7 15.4477 7.44772 15 8 15H16C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17H8C7.44772 17 7 16.5523 7 16ZM8 18C7.44772 18 7 18.4477 7 19C7 19.5523 7.44772 20 8 20H16C16.5523 20 17 19.5523 17 19C17 18.4477 16.5523 18 16 18H8Z"
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill={stroke}
    />
  </svg>
);
