import gql from 'graphql-tag';

const BookingFragment = gql`
  fragment BookingFragment on Booking {
    id
    bookingTimestamp
    bookingReference
    vehicle {
      formattedLicensePlate
      licensePlate
      VIN
      type
    }
    customer {
      title
      firstName
      lastName
      email
      phone
    }
  }
`;

export const GET_BOOKING_EVENTS_BY_RETAILER_QUERY = gql`
  query bookingEventsByRetailer {
    bookingEventsByRetailer {
      eventId
      type
      state
      retailerId
      created
      handledBy
      handled
      origin
      important
      booking {
        ...BookingFragment
      }
    }
  }
  ${BookingFragment}
`;

export const GET_BOOKINGS_BY_RETAILER_QUERY = gql`
  query bookingsByRetailer {
    bookingsByRetailer {
      ...BookingFragment
      bookingTimestamp
    }
  }
  ${BookingFragment}
`;

export const SUBSCRIPTION_BOOKING_STATUS_QUERY = gql`
  subscription bookingStatus {
    bookingStatus
  }
`;

export const SUBSCRIPTION_NEW_BOOKING_CREATED_QUERY = gql`
  subscription bookingCreated {
    bookingCreated
  }
`;

export const SUBSCRIPTION_BOOKING_CANCELLED_QUERY = gql`
  subscription bookingCancelled {
    bookingCancelled
  }
`;

export const SUBSCRIPTION_BOOKING_WORK_LIST_UPDATED_QUERY = gql`
  subscription bookingWorkListUpdated {
    bookingWorkListUpdated
  }
`;
