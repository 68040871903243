import React from 'react';

export const NewIcon = ({ size = 25, stroke = '#1C274C', tooltip }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    {tooltip && <title>{tooltip}</title>}
    <path
      d="M5.10645 1.70213C5.10645 0.762069 5.86851 0 6.80857 0V0C7.74863 0 8.5107 0.762069 8.5107 1.70213V2.79787C8.5107 3.73793 7.74863 4.5 6.80857 4.5V4.5C5.86851 4.5 5.10645 3.73793 5.10645 2.79787V1.70213Z"
      fill={stroke}
    />
    <path
      d="M15.3193 1.70213C15.3193 0.762069 16.0814 0 17.0215 0V0C17.9615 0 18.7236 0.762069 18.7236 1.70213V2.79787C18.7236 3.73793 17.9615 4.5 17.0215 4.5V4.5C16.0814 4.5 15.3193 3.73793 15.3193 2.79787V1.70213Z"
      fill={stroke}
    />
    <path
      d="M2 3C0.895431 3 0 3.89543 0 5V7.5H23.8298V5C23.8298 3.89543 22.9344 3 21.8298 3H2ZM23.8298 9.15H0V22C0 23.1046 0.895432 24 2 24H21.8298C22.9344 24 23.8298 23.1046 23.8298 22V9.15ZM6.35097 16.6804C5.83178 16.2196 5.83178 15.4725 6.35097 15.0117C6.87016 14.5509 7.71193 14.5509 8.23112 15.0117L10.4506 16.9816L15.3518 12.6316C15.871 12.1708 16.7128 12.1708 17.2319 12.6316C17.7511 13.0924 17.7511 13.8395 17.2319 14.3003L11.6573 19.2481C11.5977 19.3453 11.5216 19.4365 11.429 19.5187C10.9098 19.9795 10.0681 19.9795 9.54888 19.5187L6.35097 16.6804Z"
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill={stroke}
    />
  </svg>
);
