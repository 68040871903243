import React from 'react';
import { VehicleDetailsView } from './VehicleDetailsView';

const VehicleDetails = ({
  vehicle = {},
  customerDetails = {},
  loading = false,
  closeAppointmentDetails,
  appointmentDetails = {},
  cancelBooking,
  bookingId,
  booking,
  bookingWorkListUpdated,
}) => {
  return (
    <VehicleDetailsView
      closeAppointmentDetails={closeAppointmentDetails}
      cancelBooking={cancelBooking}
      vehicle={vehicle}
      customerDetails={customerDetails}
      loading={loading}
      appointmentDetails={appointmentDetails}
      bookingId={bookingId}
      booking={booking}
      bookingWorkListUpdated={bookingWorkListUpdated}
    />
  );
};

export { VehicleDetails };
