import React, { useState, useEffect } from 'react';
import { Spinner } from 'vcc-ui';
import styled from 'styled-components';
import { icons } from '@vcc-gcs/bookingengine-shared-frontend';
import { colors } from '../shared/domain/constants';

const getSpinnerSize = (imageSize) => {
  switch (imageSize) {
    case 's':
      return 30;
    case 'l':
      return 75;
    default:
      return 50;
  }
};

const getPixelWidth = (size) => {
  switch (size) {
    case 's':
      return 200;
    case 'l':
      return 600;
    default:
      return 400;
  }
};

const widthHeightRatio = 1.78;

const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;

  img {
    height: auto;
    max-width: ${(props) => (props.isLoading || props.hasError ? 0 : getPixelWidth(props.size))}px;
    width: 100%;
  }

  .alt-content-wrapper {
    align-items: center;
    display: flex;
    background-color: ${(props) => (props.hasError ? colors.vccUi.grey8 : '')};
    flex: 1 0 100%;
    height: ${(props) => getPixelWidth(props.size) / widthHeightRatio}px;
    justify-content: center;
    width: ${(props) => getPixelWidth(props.size)}px;
  }

  .fallback-car-svg {
    fill: ${colors.grey1};
    transform: scale(${(props) => getPixelWidth(props.size) / 48});
  }
`;

export const VehicleImage = ({ url, size = 's', className }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
    setIsLoading(true);
  }, [url]);

  const handleError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  return (
    <StyledImageWrapper size={size} isLoading={isLoading} hasError={hasError} className={className}>
      {!hasError && <img src={url} alt="" onLoad={() => setIsLoading(false)} onError={handleError} />}
      {(isLoading || hasError) && (
        <div className="alt-content-wrapper">
          {isLoading ? (
            <Spinner size={getSpinnerSize(size)} />
          ) : (
            <icons.Car className="fallback-car-svg" fill={colors.vccUi.grey7} />
          )}
        </div>
      )}
    </StyledImageWrapper>
  );
};
