import React from 'react';
import { Text } from 'vcc-ui';
import { HorizontalLayout, Translation } from '@vcc-gcs/bookingengine-shared-frontend';
import styled from 'styled-components';

const StyledHorizontalLayout = getStyledHorizontalLayout();
const StyledText = getStyledText();
const Head = ({ event, show }) => {
  return (
    <StyledHorizontalLayout>
      <Text subStyle={'emphasis'}>
        <Translation textId={`appointments.eventType.appointment.${event.type}`} />
      </Text>
      <StyledText data-test-id={'event-type'} className={'event-type'} event={event.type}>
        <Translation textId={`appointments.eventType.${event.type}`} />
      </StyledText>
      <HorizontalLayout className={'details'}>
        <Text
          data-test-id={'show-hide-text'}
          className={'show-hide-text'}
          onClick={() => show.setShowContent(!show.showContent)}
        >
          <Translation textId={`appointments.eventType.appointment.${show.showContent ? 'hide' : 'show'}`} />
        </Text>
      </HorizontalLayout>
    </StyledHorizontalLayout>
  );
};

//#1CA2FF when expanded #F4F8FE when collapsed
function getStyledHorizontalLayout() {
  return styled(HorizontalLayout)`
    align-items: center;
    padding: 0em 0.2em 0em 0.4em;
    border: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    flex: 0 0 60%;
    .show-hide-text {
      cursor: pointer;
      color: #005fcd;
      margin: 0.3em;
    }
    .details {
      display: flex;
      flex: 1 0;
      justify-content: flex-end;
    }
  `;
}

function getStyledText() {
  return styled(Text)`
    &.event-type {
      margin: 0.2em;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      height: auto;
      border-radius: 100px;
      padding: 0px 10px;
      background-color: ${(props) => {
        if (props.event === 'new') {
          return '#177E2E';
        } else if (props.event === 'cancelled') {
          return '#FF2600';
        } else {
          return '#FF8A00';
        }
      }};
      font-size: calc(10px + 1vmin);
      min-width: 50px;
    }
    @media (max-width: 600px) {
      &.event-type {
        font-size: calc(8px + 2vmin);
        min-width: 70px;
      }
    }
  `;
}

export { Head };
